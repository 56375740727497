/* eslint-disable no-console */
import Vue from 'vue';
import Router from 'vue-router';
import localForage from 'localforage';
// eslint-disable-next-line no-unused-vars
// import localforageGetItems from 'localforage-getitems';

import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(
        /* webpackChunkName: "home" */
        './views/Home2.vue'
      ),
    },
    {
      path: '/404',
      name: 'not-found',
      component: () => import(/* webpackChunkName: "404Page" */ './views/404Page.vue'),
    },
    {
      path: '*',
      name: 'no-item',
      redirect: '/404',
    },
    {
      path: '/courses',
      name: 'courses',
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(
        /* webpackChunkName: "courses" */
        './views/Courses.vue'
      ),
    },
    {
      path: '/courses/:id',
      name: 'course',
      component: () => import(/* webpackChunkName: "courseItem" */ './views/CourseItem.vue'),
    },
    {
      path: '/courses/:id/:slug',
      name: 'courseSlug',
      component: () => import(/* webpackChunkName: "courseItem" */ './views/CourseItem.vue'),
      children: [
        {
          path: '/courses/:id/:slug/*',
          component: () => import(/* webpackChunkName: "courseItem" */ './views/CourseItem.vue'),
        },
      ],
    },
    {
      path: '/programs',
      name: 'programs',
      component: () => import(/* webpackChunkName: "Programs" */ './views/Programs.vue'),
    },
    {
      path: '/programs/:alpha',
      name: 'program',
      component: () => import(/* webpackChunkName: "ProgramItem" */ './views/ProgramItem.vue'),
    },
    {
      path: '/programs/:alpha/:slug',
      name: 'programSlug',
      component: () => import(/* webpackChunkName: "ProgramItem" */ './views/ProgramItem.vue'),
      children: [
        {
          path: '/programs/:alpha/:slug/*',
          component: () => import(/* webpackChunkName: "ProgramItem" */ './views/ProgramItem.vue'),
        },
      ],
    },
    // PAST YEARS BEGIN
    {
      path: '/:year(.[0-9/-]+)',
      name: 'yearHome',
      component: () => import(/* webpackChunkName: "YearHome" */ './views/pastYears/YearHome.vue'),
    },
    {
      path: '/:year/programs',
      name: 'pastPrograms',
      component: () => import(/* webpackChunkName: "PastPrograms" */ './views/pastYears/PastPrograms.vue'),
    },
    {
      path: '/:year/programs/:alpha',
      name: 'pastProgram',
      component: () => import(/* webpackChunkName: "PastProgramItem" */ './views/pastYears/PastProgramItem.vue'),
    },
    {
      path: '/:year/programs/:alpha/:slug/',
      name: 'pastProgramSlug',
      component: () => import(/* webpackChunkName: "PastProgramItem" */ './views/pastYears/PastProgramItem.vue'),
      children: [
        {
          path: '/:year/programs/:alpha/:slug/*',
          component: () => import(/* webpackChunkName: "PastProgramItem" */ './views/pastYears/PastProgramItem.vue'),
        },
      ],
    },
    {
      path: '/:year/courses',
      name: 'pastCourses',
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(
        /* webpackChunkName: "courses" */
        './views/pastYears/PastCourses.vue'
      ),
    },
    {
      path: '/:year/courses/:id',
      name: 'pastCourseItem',
      component: () => import(/* webpackChunkName: "pastCourseItem" */ './views/pastYears/PastCourseItem.vue'),
    },
    {
      path: '/:year/courses/:id/:slug',
      name: 'pastCourseSlug',
      component: () => import(/* webpackChunkName: "pastCourseItem" */ './views/pastYears/PastCourseItem.vue'),
      children: [
        {
          path: '/:year/courses/:id/:slug/*',
          component: () => import(/* webpackChunkName: "pastCourseItem" */ './views/pastYears/PastCourseItem.vue'),
        },
      ],
    },
    {
      path: '/:year/subjects',
      name: 'pastSubjects',
      // route level code-splitting
      // which is lazy-loaded when the route is visited.
      component: () => import(
        /* webpackChunkName: "pastSubjects" */
        './views/pastYears/PastSubjects.vue'
      ),
    },
    {
      path: '/:year/subjects/:code',
      name: 'pastSubject',
      component: () => import(/* webpackChunkName: "pastSubject" */ './views/pastYears/PastSubjectItems.vue'),
    },
    {
      path: '/:year/subjects/:code/:slug/*',
      name: 'pastSubjectSlug',
      component: () => import(/* webpackChunkName: "pastSubjectItems" */ './views/pastYears/PastSubjectItems.vue'),
      children: [
        {
          path: '/:year/subjects/:code/:slug/*',
          component: () => import(/* webpackChunkName: "pastSubjectItems" */ './views/pastYears/PastSubjectItems.vue'),
        },
      ],
    },
    // PAST YEARS END
    {
      path: '/programs/:name',
      name: 'programNamed',
      component: () => import(/* webpackChunkName: "ProgramItem" */ './views/ProgramItem.vue'),
    },
    {
      path: '/subjects',
      name: 'subjects',
      component: () => import(/* webpackChunkName: "Subjects" */ './views/Subjects.vue'),
    },
    {
      path: '/subjects/:code',
      name: 'subject',
      component: () => import(/* webpackChunkName: "SubjectItems" */ './views/SubjectItems.vue'),
    },
    {
      path: '/subjects/:code/:slug',
      name: 'subjectSlug',
      component: () => import(/* webpackChunkName: "SubjectItems" */ './views/SubjectItems.vue'),
      children: [
        {
          path: '/subjects/:code/:slug/*',
          component: () => import(/* webpackChunkName: "SubjectItems" */ './views/SubjectItems.vue'),
        },
      ],
    },
    {
      path: '/careers',
      name: 'careers',
      component: () => import(/* webpackChunkName: "Careers" */ './views/Careers.vue'),
    },
    {
      path: '/careers/:code',
      name: 'career',
      component: () => import(/* webpackChunkName: "careerItems" */ './views/CareerItems.vue'),
    },
    {
      path: '/careers/:code/:slug',
      name: 'careerSlug',
      component: () => import(/* webpackChunkName: "careerItems" */ './views/CareerItems.vue'),
      children: [
        {
          path: '/careers/:code/:slug/*',
          component: () => import(/* webpackChunkName: "careerItems" */ './views/CareerItems.vue'),
        },
      ],
    },
    {
      path: '/transfers',
      name: 'transfers',
      component: () => import(/* webpackChunkName: "Transfers" */ './views/Transfers.vue'),
    },
    {
      path: '/transfer-schools/:shortname',
      name: 'transfer-schools',
      component: () => import(/* webpackChunkName: "TransferItem" */ './views/SchoolItem.vue'),
    },
    {
      path: '/search/',
      name: 'search',
      component: () => import(/* webpackChunkName: "Search" */ './views/Search.vue'),
    },
    {
      path: '/backpack/',
      name: 'backpack',
      component: () => import(/* webpackChunkName: "Backpack" */ './views/Backpack.vue'),
    },
    {
      path: '/import-backpack/',
      name: 'import-backpack',
      component: () => import(/* webpackChunkName: "ImportBackpack" */ './views/ImportBackpack.vue'),
    },
    /* {
      path: '*',
      redirect: '/',
    }, */
  ],
  scrollBehavior(to) {
    // (to, from, savedPosition) = params
    if (to.hash === '#topModal') {
      return {
        selector: to.hash,
        offset: { x: 0, y: 300 },
      };
    }
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 120 },
      };
    }
    return { x: 0, y: 0 };
  },
  linkActiveClass: 'active',
});

const waitForStorageToBeReady = async (to, from, next) => {
  // document.title = to.params.id || to.params.name || 'Columbus State Catalog';
  document.body.style.overflow = 'auto';

  // console.log('before restore');
  await localForage.getItem('generalSettings').then((value) => {
    if (value === null) {
      store.commit('initialize');
    } else {
      // nada
    }
  }).catch((err) => {
    console.log(err);
  });
  // console.log('after restore');
  next();
};
router.beforeEach(waitForStorageToBeReady);

export default router;
