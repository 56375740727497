<template>

  <b-container fluid class="topbar">
    <b-container class="topbar-nav">
      <a class="cs-top mr-auto" href="https://www.cscc.edu" target="_blank"><p class="sr-only">Columbus State Community College</p><span>CSCC</span>.EDU</a>

      <div class="topbar-btns">
        <a href="https://www.cscc.edu/admissions/apply.shtml" id="main-apply-button" class="btn apply">Apply</a>

        <!-- <button class="btn btn-primary tog-serv" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span>Tools</span>
        </button> -->

        <div class="service-items hide-mobile">
            <ul class="navbar-nav service-list">
              <li class="nav-item visit"><a class="nav-top nav-link btn btn-dark-blue" href="https://www.cscc.edu/admissions/visit.shtml">Visit</a></li>
              <li class="nav-item"><a class="nav-top nav-link" href="https://courses.cscc.edu" target="_blank" rel="noopener">Blackboard</a></li>
              <li class="nav-item"><a class="nav-top nav-link" href="https://cougarweb.cscc.edu" target="_blank" rel="noopener">Cougarweb</a></li>
              <li class="nav-item"><a class="nav-top nav-link" href="https://www.cscc.edu/services/student-email.shtml#Email">Email</a></li>
              <li class="nav-item"><a class="nav-top nav-link" href="https://web.cscc.edu/directory/default.aspx" target="_blank" rel="noopener">Directory</a></li>
            </ul>
        </div>

        <!-- <button type="button" class="btn btn-primary search-btn" data-toggle="modal" data-target="#searchModal" id="search-btn" aria-label="Search">
          <span>
            <svg width="13" height="13" viewBox="0 0 13 13">
              <path d="m4.8495 7.8226c0.82666 0 1.5262-0.29146 2.0985-0.87438 0.57232-0.58292 0.86378-1.2877 0.87438-2.1144 0.010599-0.82666-0.28086-1.5262-0.87438-2.0985-0.59352-0.57232-1.293-0.86378-2.0985-0.87438-0.8055-0.010599-1.5103 0.28086-2.1144 0.87438-0.60414 0.59352-0.8956 1.293-0.87438 2.0985 0.021197 0.8055 0.31266 1.5103 0.87438 2.1144 0.56172 0.60414 1.2665 0.8956 2.1144 0.87438zm4.4695 0.2115 3.681 3.6819-1.259 1.284-3.6817-3.7 0.0019784-0.69479-0.090043-0.098846c-0.87973 0.76087-1.92 1.1413-3.1207 1.1413-1.3553 0-2.5025-0.46363-3.4417-1.3909s-1.4088-2.0686-1.4088-3.4239c0-1.3553 0.4696-2.4966 1.4088-3.4239 0.9392-0.92727 2.0864-1.3969 3.4417-1.4088 1.3553-0.011889 2.4906 0.45771 3.406 1.4088 0.9154 0.95107 1.379 2.0924 1.3909 3.4239 0 1.2126-0.38043 2.2588-1.1413 3.1385l0.098834 0.090049z"></path>
            </svg>
          </span>
        </button> -->
      </div>
    </b-container>
    <!-- <div class="collapse navbar-collapse service-items hide-full" id="navbarNavDropdown">
        <ul class="navbar-nav service-list">
          <li class="nav-item"><a class="nav-top nav-link tb-link" href="/admissions/visit.shtml">Visit</a></li>
        <li class="nav-item"><a class="nav-top nav-link tb-link" href="https://courses.cscc.edu" target="_blank" rel="noopener">Blackboard</a></li>
        <li class="nav-item"><a class="nav-top nav-link tb-link" href="https://cougarweb.cscc.edu" target="_blank" rel="noopener">Cougarweb</a></li>
        <li class="nav-item"><a class="nav-top nav-link tb-link" href="/services/student-email.shtml#Email">Email</a></li>
        <li class="nav-item"><a class="nav-top nav-link tb-link" href="https://web.cscc.edu/directory/default.aspx" target="_blank" rel="noopener">Directory</a></li>
            <li class="nav-item btn-close-services">
              <button class="btn btn-custom btn-dark-blue btn-close-services" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle Services Menu">
              Close Tools
          </button>
        </li>
        </ul>
    </div> -->
  </b-container>

</template>

<script>
/* import store from '../store'; */

export default {
  data() {
    return {
      search: '',
      searchItems: '',
      hasSearchItems: false,
    };
  },
  /* mounted: {
  },
  methods: {
  },
  computed: {
  }, */
};
</script>

<style lang="scss">

  .topbar {
    background-color: white;
    position: fixed;
    max-height: 2.8rem;
    width: 100%;
    top: 0;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    a {
      font-size: .7em !important;
    }
    .topbar-nav {
      justify-content: flex-end;
      padding: 0.25rem 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 1140px;
      margin-left: calc(50% - (1140px / 2));
      @media screen and (max-width: 1199px) {
        max-width: 960px;
        margin-left: calc(50% - (960px / 2));
      }
      @media screen and (max-width: 991px) {
        max-width: 740px;
        margin-left: calc(50% - (740px / 2));
      }
      @media screen and (max-width: 767px) {
        max-width: 540px;
        margin-left: calc(50% - (540px / 2));
      }
      @media screen and (max-width: 576px) {
        max-width: 100%;
        margin-left: 0;
      }
      .topbar-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        .navbar-nav {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        a {
          color: #6c757d;
          text-transform: uppercase;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .cs-top {
      text-decoration: none !important;
      color: rgba(50,50,50,0.8) !important;
      border-bottom: 1px solid white;
      transition: all 0.2s ease-out;
      padding: 0 !important;
    }
    .apply, .visit a {
      font-weight: bold;
      font-size: .8rem;
      text-transform: uppercase;
      background-color: #953150 !important;
      color: white !important;
      //border: 1px solid #953150;
      border-radius: 3px;
      line-height: 1.3;
      transition: all 0.2s ease-out;
      height: 20px;
      display: flex;
      align-items: center;
      padding: .75rem .5rem !important
    }
    .apply {
      &:hover {
        background-color: #c5426b !important;
      }
    }
    .visit a {
      background-color: #003E52 !important;
      &:hover {
        background-color: #007298 !important;
      }
    }
  }

</style>
