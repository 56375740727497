// import 'es6-promise/auto';
// import 'core-js';
// import 'regenerator-runtime/runtime';
import './poly';
import Vue from 'vue';
// import BootstrapVue from 'bootstrap-vue';
import {
  LayoutPlugin,
  DropdownPlugin,
  TablePlugin,
  NavbarPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  FormCheckboxPlugin,
  ModalPlugin,
  BadgePlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  AlertPlugin,
  CardPlugin,
  ListGroupPlugin,
  BreadcrumbPlugin,
  PopoverPlugin,
  SpinnerPlugin,
  TooltipPlugin,
  PaginationPlugin,
  ToastPlugin,
} from 'bootstrap-vue';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/scss/main.scss';
import './registerServiceWorker';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

let gParams = {};
if (process.env.NODE_ENV === 'development') {
  gParams = {
    debug_mode: 'false',
  };
}
Vue.use(VueGtag, {
  config: {
    id: 'G-LC62SG9V3X',
    params: gParams,
  },
});
/* import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'; */
// import '@/scss/main.scss';

// Vue.use(BootstrapVue);
Vue.use(LayoutPlugin);
Vue.use(DropdownPlugin);
Vue.use(TablePlugin);
Vue.use(NavbarPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(AlertPlugin);
Vue.use(CardPlugin);
Vue.use(ListGroupPlugin);
Vue.use(PopoverPlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TooltipPlugin);
Vue.use(PaginationPlugin);
Vue.use(ToastPlugin);

// Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
