<template>
  <div id="app" :key="$route.fullPath">
    <!-- BOOKBACK CONFIRM SEND MODAL -->
    <b-modal ref="modalPackConfirm" hide-footer id="packModal">
      <template v-slot:modal-title>
        Send your backpack
      </template>
      <div>
        <h3 class="mt-0 mb-3">Add a name for your pack:</h3>
        <b-form @submit="linkBuilder">
          <b-form-group
          id="input-group-1"
          label="Backpack Name:"
          label-for="packNamer"
          description=""
          >
          <b-form-input
              id="packNamer"
              v-model="packName"
              type="text"
              required
              placeholder="Enter a name"
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">Send</b-button>
        </b-form>
      </div>
      <!-- <b-button class="mt-3" block @click="$bvModal.hide('modalPackConfirm')">Close</b-button> -->
    </b-modal>
    <b-alert class="year-banner d-none" variant="danger" show dismissible ref="alertBox" @dismissed="dismissAlert">
      <strong>{{currentCatalogYear}}</strong> courses and programs.
    </b-alert>
    <Topbar></Topbar>
    <Navbar :key="generateKey('navbar')"
      :searchTerm = "searchTerm"
      :cartHasCourses = "cartHasCourses"
      @delete="deleteItem"
      @send="confrimPackModal"
      @register="registerCourses"
      @getLink="linkBuilder"
      @updateSearch="updateSearchTerm">
    </Navbar>
    <router-view
      :searchTerm = "searchTerm"
      :products="products"
      :prevYears ="prevYears"
      @add="addItem"
      @delete="deleteItem"
      class="animated fadeIn"
      @register="registerCourses"
    ></router-view>
    <Footer :key="generateKey('footer')" :style="`margin-bottom:${alertMargin}px`"></Footer>
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue';
import update from './mixins/update';
// import Navbar from '@/components/Navbar.vue';
// import Footer from '@/components/Footer.vue';

import store from './store';

const Navbar = () => import(
  /* webpackChunkName: "navbar" */
  './components/Navbar.vue'
);
const Footer = () => import(
  /* webpackChunkName: "footer" */
  './components/Footer.vue'
);

export default {
  name: 'app',
  components: {
    Topbar,
    Navbar,
    Footer,
  },
  data() {
    return {
      cart: [],
      products: null,
      courseTotal: 0,
      searchTerm: '',
      publicPath: process.env.BASE_URL,
      packName: '',
      cartLink: '',
      finalLink: '',
      currentCatalogYear: '2024-2025',
      alertMargin: 0,
      searchItems: [],
      version: '1.0.4',
      prevYears: ['2021-2022', '2022-2023', '2023-2024'],
    };
  },
  mixins: [update],
  metaInfo() {
    return {
      script: [
        {
          innerHTML: `{
          "@context" : "http://schema.org",
          "@type" : "Organization",
          "name" : "Columbus State Community College ",
          "url" : "https://www.cscc.edu",
          "address" :
          [{
          "@type" : "PostalAddress",
          "streetAddress" : "550 East Spring St.",
          "addressLocality" : "Columbus",
          "addressRegion" : "OH",
          "postalCode" : "43215",
          "addressCountry": "USA",
          "telephone": "+16142875353",
          "email": "information@cscc.edu"
          },
          {
          "@type" : "PostalAddress",
          "streetAddress" : "5100 Cornerstone Dr.",
          "addressLocality" : "Delaware",
          "addressRegion" : "OH",
          "postalCode" : "43015",
          "addressCountry": "USA",
          "telephone": "+17402038345",
          "email": "delaware@cscc.edu"
          }],

          "logo": "https://www.cscc.edu/_resources/images/CSCC_2color_primary_black_blue.png",
          "sameAs" :
          [
          "https://twitter.com/cscc_edu/",
          "https://www.youtube.com/user/ColumbusState",
          "https://www.instagram.com/cscc_edu/",
          "https://www.facebook.com/CSCC.edu/",
          "https://www.linkedin.com/school/163153/",
          "https://www.snapchat.com/add/cscc.edu"
          ]
        }`,
          type: 'application/ld+json',
        },
      ],
    };
  },
  mounted() {
    this.matchAlertHeight();

    // INIT TIMESTAMPS - Needs cleaning up. Lots of promise shenanigans here.
    const timestamps = Promise.resolve(this.$store.dispatch('loadTimestamps'));
    timestamps.then(() => {
      const loadSearch = Promise.resolve(this.$store.dispatch('loadDataItem', {
        key: 'searchList',
        url: 'getCatalogItem?type=search&item=all',
        commit: 'mutateSearch',
        source: 'root',
      }));
      loadSearch.then((val) => {
        this.searchItems = val;
        // console.log(this.$route.name);
        if (this.$route.name !== 'programs') {
          Promise.resolve(this.$store.dispatch('loadDataItem', { key: 'programs', url: 'getCatalogItem?type=program&item=all', commit: 'mutatePrograms' }));
        }
        if (this.$route.name !== 'courses') {
          Promise.resolve(store.dispatch('loadDataItem', { key: 'courses', url: 'getCatalogItem?type=course&item=all', commit: 'mutateCourses' }));
        }
        if (this.$route.name !== 'transfers') {
          Promise.resolve(this.$store.dispatch('loadDataItem', { key: 'transferGuides', url: 'getCatalogItem?type=transfers&item=all', commit: 'mutateTransferGuides' }));
        }
      });
    });

    // DO STUFF HERE MAYBE
    const loadCart = Promise.resolve(store.dispatch('loadBookbag'));
    loadCart.then((val) => {
      this.cart = val;
    });
  },
  created() {
    window.addEventListener('resize', this.resizeWindow);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeWindow);
  },
  methods: {
    resizeWindow() {
      this.matchAlertHeight();
    },
    dismissAlert() {
      this.alertMargin = 0;
    },
    matchAlertHeight() {
      const height = this.$refs.alertBox.$el.clientHeight;
      this.alertMargin = height;
    },
    registerCourses() {
      let holdCourses = '';
      for (let i = 0; i < this.cart.length; i += 1) {
        if (this.cart[i].type === 'courses') {
          holdCourses += `${this.cart[i].product.courseID} `;
        }
      }
      if (holdCourses === '') { return; }
      window.open(`https://selfservice.cscc.edu/Student/Student/Courses/Search?keyword=${holdCourses}`);
    },
    confrimPackModal() {
      this.$refs.modalPackConfirm.show();
    },
    async linkBuilder(evt) {
      evt.preventDefault();
      const theCart = this.cart;
      const theName = this.packName;
      const theData = {
        name: theName,
        data: theCart,
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(theData),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      fetch(`${store.state.generalSettings.apiUrl}/genLink`, options)
        .then((response) => response.json())
        .then((data) => {
          this.cartLink = data;
          let port = ':8080';
          const baseURL = '';
          if (process.env.NODE_ENV === 'production') {
            port = '';
            // baseURL = '/catalog-vue';
          }
          this.finalLink = `${window.location.protocol}//${window.location.hostname}${port}${baseURL}${this.cartLink}`;
          this.sendCart();
        });
    },
    async sendCart() {
      try {
        const theLink = this.finalLink;
        await navigator.clipboard.writeText(theLink);
        this.$refs.modalPackConfirm.hide();
        this.loadToastSentCart('success');
      } catch (err) {
        // console.error('Failed to copy: ', err);
        this.loadToastSentCart('failed');
      }
    },
    reloadCart(data) {
      this.cart = data;
      store.commit('updateBookbag', data);
    },
    updateSearchTerm(term) {
      this.searchTerm = term;
    },
    generateKey(type) {
      // Used to cycle certain elements on every page load (likely worth it to do this as little as possible since it means extra overhead)
      const defaultKey = this.$route.fullPath.replace(/\//g, '-').substring(1);
      const key = `${defaultKey}-${type}`;
      return key;
    },
    loadToastSentCart(status) {
      let message = `Failed to send ${this.cart.length} items from the ${this.packName} Backpack! Sorry, please try again in a moment.`;
      let theTitle = `${this.packName} Failed to Send`;
      let theVar = 'danger';
      if (status === 'success') {
        message = `Successfully sent ${this.cart.length} items from the ${this.packName} Backpack!`;
        theTitle = `${this.packName} Sent Successfully!`;
        theVar = 'success';
      }
      this.$bvToast.toast(message, {
        title: theTitle,
        variant: theVar,
        isStatus: true,
        solid: false,
        autoHideDelay: 3000,
      });
    },
    loadToast(id, variantType, messageType) {
      let message = `${id} Saved to Backpack`;
      let toastTitle = 'Backpack Updated';
      if (messageType === 'already') { message = `${id} Already in Backpack!`; toastTitle = 'No Action'; }
      if (messageType === 'remove') { message = `${id} Removed from Backpack.`; }
      if (messageType === 'all') { message = 'All Items Removed from Backpack.'; }
      if (variantType === 'success') { message = `All ${id} Courses Added to Backpack`; }
      this.$bvToast.toast(message, {
        title: toastTitle,
        variant: variantType,
        isStatus: true,
        solid: false,
        autoHideDelay: 1200,
      });
    },
    deleteItem(theId) {
      let id = theId;
      if (id === 'all') {
        this.cart = [];
        store.state.isCartShow = false;
        this.loadToast(id, 'danger', 'all');
        store.commit('updateBookbag', this.cart);
        return;
      }
      for (let i = 0; i < this.cart.length; i += 1) {
        if (this.cart[i].id === id) {
          // console.log('we matched, I should delete you');
          if (this.cart[i].type === 'careers') {
            id = this.cart[i].product.career.title;
          }
          if (this.cart[i].type === 'transfer') {
            id = `${this.cart[i].product.FOUR_YEAR_SCHOOL_NAME} - ${this.cart[i].product.FOUR_YEAR_MJR} (${this.cart[i].product.FOUR_YEAR_CRED}) Transfer Guide`;
          }
          this.cart.splice(i, 1);
          if (this.cart.length === 0) {
            store.state.isCartShow = false;
          }
        }
      }
      this.loadToast(id, 'danger', 'remove');
      store.commit('updateBookbag', this.cart);
      // console.log(this.cart);
      /* if (this.cart[id].qty > 1) {
        this.cart[id].qty--;
      } else {
        this.cart.splice(id, 1);
      } */
    },
    checkCart(product, type) {
      // SET ID
      let id = product.name;
      if (type === 'courses' || type === 'courseIn') {
        id = product.courseID;
      } else if (type === 'subject') {
        id = product[0].type;
      } else if (type === 'careers') {
        id = product.code;
      } else if (type === 'searchCourse') {
        // eslint-disable-next-line prefer-destructuring
        id = product.id;
      } else if (type === 'searchPrograms') {
        id = product.name;
      } else if (type === 'searchSubject') {
        id = product.code;
      } else if (type === 'searchCareers') {
        id = product.code;
      } else if (type === 'transfer') {
        id = product.ID;
      }
      // CHECK IF ALREADY IN CART
      // eslint-disable-next-line no-unused-vars
      // let whichProduct;
      const existing = this.cart.filter((item) => {
        if (item.id === id) {
          // whichProduct = index;
          return true;
        }
        return false;
      });
      return existing;
    },
    async addItem(theProduct, thisType) {
      let type = thisType;
      let product = theProduct;
      // console.log('Adding ITEM')
      // SET ID
      let id = product.name;
      if (type === 'courses' || type === 'courseIn') {
        id = product.courseID;
      } else if (type === 'subject') {
        id = product[0].type;
      } else if (type === 'careers') {
        id = product.code;
      } else if (type === 'searchCourse') {
        // eslint-disable-next-line prefer-destructuring
        id = product.id;
      } else if (type === 'searchPrograms') {
        id = product.name;
      } else if (type === 'searchSubject') {
        id = product.code;
      } else if (type === 'searchCareers') {
        id = product.code;
      } else if (type === 'transfer') {
        id = product.ID;
      }
      // CHECK IF ALREADY IN CART
      // eslint-disable-next-line no-unused-vars
      // let whichProduct;
      const existing = this.cart.filter((item) => {
        if (item.id === id) {
          // whichProduct = index;
          return true;
        }
        return false;
      });

      // If the item is not really a data object (like search items) will fetch the proper item from the api and add it to the cart
      function loadDataItem(theType, item, theThis) {
        // console.log('NOT REAL ITEM!');
        try {
          fetch(`${store.state.generalSettings.apiUrl}/getCatalogItem?type=${theType}&item=${item}`)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              }
              return false;
            })
            .then((data) => {
              product = data;
            })
            .then(() => {
              let varType = 'info';
              if (existing.length) {
                // console.log('already exists');
                theThis.loadToast(id, 'danger', 'already');
              } else {
                // console.log('doesnt exist pushing');
                const pushProd = product;
                // if (type === 'careers') { pushProd = product[0]; }
                theThis.cart.push({
                  id,
                  type,
                  product: pushProd,
                });
                store.commit('updateBookbag', theThis.cart);
                if (type === 'programs') {
                  if (product.type === 'cert') { varType = 'primary'; }
                  if (product.type === 'degree') { varType = 'warning'; }
                }
                if (type === 'subject') { varType = 'success'; }
                if (type === 'careers') { varType = 'secondary'; id = product.career.title; }
                theThis.loadToast(id, varType, 'new');
              }
            });
          return product;
        } catch (err) {
          return err;
        }
      }

      // TODO DRY this thing (need to change what happens in the else and last then)
      if (type === 'courseIn') {
        type = 'courses';
        loadDataItem('course', id, this);
      } else if (type === 'searchCourse') {
        type = 'courses';
        loadDataItem('course', id, this);
      } else if (type === 'searchPrograms') {
        type = 'programs';
        loadDataItem('program', id, this);
      } else if (type === 'searchSubject') {
        type = 'subject';
        loadDataItem('subject', id, this);
      } else if (type === 'searchCareers') {
        type = 'careers';
        loadDataItem('career', id, this);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (existing.length) {
          // console.log('already exists');
          if (type === 'transfer') { id = `${product.FOUR_YEAR_SCHOOL_NAME} - ${product.FOUR_YEAR_MJR} (${product.FOUR_YEAR_CRED}) Transfer Guide`; }
          this.loadToast(id, 'danger', 'already');
        } else {
          let varType = 'info';
          // console.log('doesnt exist pushing');
          // console.log(product);
          this.cart.push({
            id,
            type,
            product,
          });
          store.commit('updateBookbag', this.cart);
          // console.log(this.cart);
          if (type === 'programs') {
            if (product.type === 'cert') { varType = 'primary'; }
            if (product.type === 'degree') { varType = 'warning'; }
          }
          if (type === 'subject') { varType = 'success'; }
          if (type === 'careers') { varType = 'secondary'; id = product.career.title; }
          if (type === 'transfer') { id = `${product.FOUR_YEAR_SCHOOL_NAME} - ${product.FOUR_YEAR_MJR} (${product.FOUR_YEAR_CRED}) Transfer Guide`; }
          this.loadToast(id, varType, 'new');
        }
        // console.log(this.cart);
      }
      /* var whichProduct;
      var existing = this.cart.filter(function(item, index) {
        if (item.product.id == Number(product.id)) {
          whichProduct = index;
          return true;
        } else {
          return false;
        }
      });
      if (existing.length) {
        this.cart[whichProduct].qty++;
      } else {
        this.cart.push({ product: product, qty: 1 });
        console.log(this.cart);
      } */
    },
  },
  computed: {
    cartHasCourses() {
      let foundCourse = false;
      for (let i = 0; i < this.cart.length; i += 1) {
        if (this.cart[i].type === 'courses') { foundCourse = true; }
      }
      return foundCourse;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // display: flex;
  min-height: 100vh;
  // flex-direction: column;
  display: block;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.b-toaster {
  z-index: 9999 !important;
  top: 7.8rem !important;
  right: calc(50% - 570px + .8rem) !important;
  @media screen and (max-width: 1199px) {
    right: calc(50% - 480px + .8rem) !important;
  }
  @media screen and (max-width: 991px) {
    right: calc(50% - 370px + .8rem) !important;
  }
  @media screen and (max-width: 767px) {
    right: calc(50% - 270px + .8rem) !important;
  }
  @media screen and (max-width: 576px) {
    right: .5rem !important;
  }
  /*.b-toaster-top-right {
     max-width: 1140px;
    margin-left: calc(50% - (1140px / 2));
    @media screen and (max-width: 1199px) {
      max-width: 960px;
      margin-left: calc(50% - (960px / 2));
    }
    @media screen and (max-width: 991px) {
      max-width: 740px;
      margin-left: calc(50% - (740px / 2));
    }
    @media screen and (max-width: 767px) {
      max-width: 540px;
      margin-left: calc(50% - (540px / 2));
    }
    @media screen and (max-width: 576px) {
      max-width: 100%;
      margin-left: 0;
    }
  }*/
}
.content {
  flex: 1;
}
#packModal___BV_modal_outer_ {
  z-index: 9999 !important;
}
</style>
