/* eslint-disable no-console */
import Vue from 'vue';
import Vuex from 'vuex';
// import createPersistedState from 'vuex-persistedstate';
import VuexPersist from 'vuex-persist';
import localForage from 'localforage';

Vue.use(Vuex);

let apiPath = `${window.location.protocol}//${window.location.hostname}:3000/api`;
if (process.env.NODE_ENV === 'production') { apiPath = 'https://academia-nut.herokuapp.com/api'; }

function compare(a, b) {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
}

// FUNCTION FOR PROMISE FOR YEAR VALUES
async function http(url, method = 'GET', data) {
  const response = await fetch(url, {
    method,
    data,
  });
  return response.json();
}

let masterTimestamp = null;

// ----------- GENERAL SETTINGS --------------
const vuexLFGeneralSettings = new VuexPersist({
  key: 'generalSettings', // The key to store the state on in the storage provider.
  storage: localForage, // or window.sessionStorage or localForage
  saveState: async (key, state, storage) => {
    // await Promise.resolve(storage.getItem(key)).then(() => {
    Promise.resolve(storage.setItem(key, state));
    // });
  },
  restoreState: async (key, storage) => { await Promise.resolve(storage.getItem(key)); },
  reducer: (state) => ({
    apiUrl: state.generalSettings.apiUrl,
    count: state.generalSettings.count,
    isCartShow: state.generalSettings.isCartShow,
    prevYears: state.generalSettings.prevYears,
  }),
});

const generalSettings = {
  state: {
    apiUrl: apiPath,
    count: 0,
    isCartShow: false,
    prevYears: [],
  },
  mutations: {
    initialize(state) {
      state.apiUrl = apiPath;
      state.count = 0;
      state.isCartShow = false;
      state.prevYears = [];
    },
    changeCartShow(state) { state.isCartShow = !state.isCartShow; },
  },
};
// ----------- END GENERAL SETTINGS --------------

// ----------- BOOKBAG --------------
const vuexLFBookbag = new VuexPersist({
  key: 'bookbag',
  storage: localForage,
  saveState: async (key, state, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (val === null) {
        Promise.resolve(storage.setItem(key, state));
      } else if (val.items === null) {
        Promise.resolve(storage.setItem(key, state));
      }
    });
  },
  restoreState: async (key, storage) => { await Promise.resolve(storage.getItem(key)); },
  reducer: (state) => ({ items: state.bookbag.items, settings: state.bookbag.items }),
});

const bookbag = {
  state: {
    items: [],
    settings: null,
  },
  mutations: {
    updateBookbag: (state, cart) => {
      localForage.getItem('bookbag').then(() => {
        const theNewBag = {
          items: cart,
          settings: {},
        };
        localForage.setItem('bookbag', theNewBag);
      });
    },
  },
  actions: {
    async loadBookbag() {
      let returnStuff = null;
      await localForage.getItem('bookbag').then((val) => {
        if (val !== null) {
          returnStuff = val.items;
        } else {
          returnStuff = bookbag.state.items;
        }
      });
      return returnStuff;
    },
  },
};
// ----------- END BOOKBAG --------------

// ----------- SEARCH LIST --------------
const searchList = {
  state: {
    items: null,
    synced: null,
    settings: null,
  },
  mutations: {
    mutateSearch(state, payload) { state.items = payload.items.sort(compare); state.synced = payload.synced; },
  },
};

const vuexLFSearchList = new VuexPersist({
  key: 'searchList',
  storage: localForage,
  saveState: async (key, state, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (state.items === null) {
        Promise.resolve(storage.setItem(key, val));
      } else {
        Promise.resolve(storage.setItem(key, state));
      }
    });
  },
  restoreState: async (key, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (val !== null) {
        searchList.state = val;
      }
    });
  },
  reducer: (state) => ({ items: state.searchList.items, synced: state.searchList.synced, settings: state.searchList.settings }),
});
// ----------- END SEARCH LIST --------------

// ----------- PROGRAMS --------------
const programs = {
  state: {
    items: null,
    synced: null,
    settings: {
      view: 'list',
      degType: 'all',
      onlineStatus: 'all',
      searchTerm: '',
      selectedPathways: [],
      selectedDepartments: [],
    },
  },
  mutations: {
    mutatePrograms(state, payload) {
      state.items = payload.items;
      state.synced = payload.synced;
      // console.log(payload.synced);
      // console.log('MUTATE PROGRAMS');
    },
    mutateProgramsSettings(state, payload) {
      state.settings[payload.type] = payload.value;
    },
    mutateProgramsDefault(state, payload) {
      state.settings = {
        view: payload.viewType,
        degType: 'all',
        onlineStatus: 'all',
        searchTerm: '',
        selectedPathways: [],
        selectedDepartments: [],
      };
    },
    mutateSettingsSearch(state, payload) {
      state.settings = {
        view: payload.displayType,
        degType: 'all',
        onlineStatus: 'all',
        searchTerm: payload.searchTerm,
        selectedPathways: [],
        selectedDepartments: [],
      };
    },
  },
  actions: {
    async loadProgramSettings() {
      let returnStuff = null;
      await localForage.getItem('programs').then((val) => {
        if (val !== null) {
          returnStuff = val.settings;
        } else {
          returnStuff = programs.state.settings;
        }
      });
      return returnStuff;
    },
  },
};

const vuexLFPrograms = new VuexPersist({
  key: 'programs',
  storage: localForage,
  saveState: async (key, state, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (state.items === null) {
        Promise.resolve(storage.setItem(key, val));
      } else {
        Promise.resolve(storage.setItem(key, state));
      }
    });
  },
  restoreState: async (key, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (val !== null) {
        programs.state = val;
      }
    });
  },
  reducer: (state) => ({ items: state.programs.items, synced: state.programs.synced, settings: state.programs.settings }),
});
// ----------- END PROGRAMS --------------

// ----------- SUBJECTS --------------
const subjects = {
  state: {
    items: null,
    synced: null,
    settings: null,
  },
  mutations: {
    mutateSubjects(state, payload) { state.items = payload.items.sort(compare); state.synced = payload.synced; },
  },
};

const vuexLFSubjects = new VuexPersist({
  key: 'subjects',
  storage: localForage,
  saveState: async (key, state, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (state.items === null) {
        Promise.resolve(storage.setItem(key, val));
      } else {
        Promise.resolve(storage.setItem(key, state));
      }
    });
  },
  restoreState: async (key, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (val !== null) {
        subjects.state = val;
      }
    });
  },
  reducer: (state) => ({ items: state.subjects.items, synced: state.subjects.synced, settings: state.subjects.settings }),
});
// ----------- END SUBJECTS --------------

// ----------- COURSES --------------
const courses = {
  state: {
    items: null,
    synced: null,
    settings: {
      currentMinCode: 0,
      currentMaxCode: 3000,
      creditHoursMin: 0,
      creditHoursMax: 8,
      selectedSubjects: [],
      page: 1,
      searchTerm: '',
      view: 'list',
    },
  },
  mutations: {
    mutateCourses(state, payload) {
      state.items = payload.items;
      state.synced = payload.synced;
    },
    mutateCoursesSettings(state, payload) {
      state.settings[payload.type] = payload.value;
    },
    mutateCourseSearchHome(state, payload) {
      state.settings = {
        currentMinCode: 0,
        currentMaxCode: 3000,
        creditHoursMin: 0,
        creditHoursMax: 8,
        selectedSubjects: [],
        page: 1,
        searchTerm: payload.searchTerm,
        view: payload.displayType,
      };
    },
    mutateCoursesSettingsDefault(state) {
      state.settings = {
        currentMinCode: 0,
        currentMaxCode: 3000,
        creditHoursMin: 0,
        creditHoursMax: 8,
        selectedSubjects: [],
        page: 1,
        searchTerm: '',
        view: 'list',
      };
    },
    mutateCoursesSettingsDefaultSearch(state, payload) {
      const newSearch = payload;
      localForage.getItem('courses').then((val) => {
        let theNewState = {};
        if (val !== null) {
          theNewState = {
            items: val.items,
            settings: {
              currentMinCode: 0,
              currentMaxCode: 3000,
              creditHoursMin: 0,
              creditHoursMax: 8,
              selectedSubjects: [],
              page: 1,
              searchTerm: newSearch,
              view: 'list',
            },
          };
        } else {
          theNewState = {
            items: [],
            settings: {
              currentMinCode: 0,
              currentMaxCode: 3000,
              creditHoursMin: 0,
              creditHoursMax: 8,
              selectedSubjects: [],
              page: 1,
              searchTerm: newSearch,
              view: 'list',
            },
          };
        }

        localForage.setItem('courses', theNewState);
        console.log('clearing all filters going to filter courses');
      });
    },
  },
  actions: {
    async loadCoursesSettings() {
      let returnStuff = null;
      await localForage.getItem('courses').then((val) => {
        if (val !== null) {
          returnStuff = val.settings;
        } else {
          returnStuff = courses.state.settings;
        }
      });
      return returnStuff;
    },
  },
};

const vuexLFCourses = new VuexPersist({
  key: 'courses',
  storage: localForage,
  saveState: async (key, state, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (state.items === null) {
        Promise.resolve(storage.setItem(key, val));
      } else {
        Promise.resolve(storage.setItem(key, state));
      }
    });
  },
  restoreState: async (key, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (val !== null) {
        courses.state = val;
      }
    });
  },
  reducer: (state) => ({ items: state.courses.items, synced: state.courses.synced, settings: state.courses.settings }),
});
// ----------- END COURSES --------------

// ----------- PATHWAYS --------------
const pathways = {
  state: {
    items: null,
    synced: null,
    settings: null,
  },
  mutations: {
    mutatePathways(state, payload) { state.items = payload.items.sort(compare); state.synced = payload.synced; },
  },
};

const vuexLFPathways = new VuexPersist({
  key: 'pathways',
  storage: localForage,
  saveState: async (key, state, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (state.items === null) {
        Promise.resolve(storage.setItem(key, val));
      } else {
        Promise.resolve(storage.setItem(key, state));
      }
    });
  },
  restoreState: async (key, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (val !== null) {
        pathways.state = val;
      }
    });
  },
  reducer: (state) => ({ items: state.pathways.items, synced: state.pathways.synced, settings: state.pathways.settings }),
});
// ----------- END PATHWAYS --------------

// ----------- DEPARTMENTS --------------
const departments = {
  state: {
    items: null,
    synced: null,
    settings: null,
  },
  mutations: {
    mutateDepartments(state, payload) { state.items = payload.items.sort(compare); state.synced = payload.synced; },
  },
};

const vuexLFDepartments = new VuexPersist({
  key: 'departments',
  storage: localForage,
  saveState: async (key, state, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (state.items === null) {
        Promise.resolve(storage.setItem(key, val));
      } else {
        Promise.resolve(storage.setItem(key, state));
      }
    });
  },
  restoreState: async (key, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (val !== null) {
        departments.state = val;
      }
    });
  },
  reducer: (state) => ({ items: state.departments.items, synced: state.departments.synced, settings: state.departments.settings }),
});
// ----------- END DEPARTMENTS --------------

// ----------- TRANSFERS --------------
const transferGuides = {
  state: {
    items: null,
    synced: null,
    settings: {
      selectedSchools: [],
      selectedDurations: [],
      page: 1,
      searchTerm: '',
      view: 'list',
    },
  },
  mutations: {
    mutateTransferGuides(state, payload) { state.items = payload.items.sort(compare); state.synced = payload.synced; },
    mutateTransferGuidesSearch(state, payload) {
      state.settings = {
        selectedSchools: [],
        selectedDurations: [],
        page: 1,
        searchTerm: payload.searchTerm,
        view: payload.displayType,
      };
    },
    mutateTransferSettings(state, payload) {
      state.settings[payload.type] = payload.value;
    },
    mutateTransferSettingsDefault(state) {
      state.settings = {
        selectedSchools: [],
        selectedDurations: [],
        page: 1,
        searchTerm: '',
        view: 'list',
      };
    },
  },
  actions: {
    async loadTransferSettings() {
      let returnStuff = null;
      await localForage.getItem('transferGuides').then((val) => {
        if (val !== null) {
          returnStuff = val.settings;
        } else {
          returnStuff = transferGuides.state.settings;
        }
      });
      return returnStuff;
    },
  },
};

const vuexLFtransferGuides = new VuexPersist({
  key: 'transferGuides',
  storage: localForage,
  saveState: async (key, state, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (state.items === null) {
        Promise.resolve(storage.setItem(key, val));
      } else {
        Promise.resolve(storage.setItem(key, state));
      }
    });
  },
  restoreState: async (key, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (val !== null) {
        transferGuides.state = val;
      }
    });
  },
  reducer: (state) => ({ items: state.transferGuides.items, synced: state.transferGuides.synced, settings: state.transferGuides.settings }),
});
// ----------- END TRANSFERS --------------

// ----------- SCHOOLS --------------
const schools = {
  state: {
    items: null,
    synced: null,
    settings: {
      searchTerm: '',
      view: 'list',
    },
  },
  mutations: {
    mutateSchools(state, payload) { state.items = payload.items.sort(compare); state.synced = payload.synced; },
  },
};

const vuexLFschools = new VuexPersist({
  key: 'schools',
  storage: localForage,
  saveState: async (key, state, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (state.items === null) {
        Promise.resolve(storage.setItem(key, val));
      } else {
        Promise.resolve(storage.setItem(key, state));
      }
    });
  },
  restoreState: async (key, storage) => {
    await Promise.resolve(storage.getItem(key)).then((val) => {
      if (val !== null) {
        schools.state = val;
      }
    });
  },
  reducer: (state) => ({ items: state.schools.items, synced: state.schools.synced, settings: state.schools.settings }),
});
// ----------- END SCHOOLS --------------

// EXPORT STORE
export default new Vuex.Store({
  modules: {
    generalSettings,
    bookbag,
    searchList,
    courses,
    subjects,
    programs,
    pathways,
    departments,
    transferGuides,
    schools,
  },
  plugins: [
    vuexLFGeneralSettings.plugin,
    vuexLFBookbag.plugin,
    vuexLFSearchList.plugin,
    vuexLFCourses.plugin,
    vuexLFSubjects.plugin,
    vuexLFPrograms.plugin,
    vuexLFPathways.plugin,
    vuexLFDepartments.plugin,
    vuexLFtransferGuides.plugin,
    vuexLFschools.plugin,
  ],
  actions: {
    async loadTimestamps() {
      return new Promise((resolve, reject) => {
        try {
          http(`${apiPath}/getSettings`)
            .then((data) => {
              masterTimestamp = data;
              resolve(data);
            });
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
    async loadDataItem({ commit }, payload) {
      const thisKey = payload.key;
      const thisUrl = payload.url;
      const thisCommit = payload.commit;
      // const thisSource = payload.source;
      let localTimestamp = 'unknown';

      let stuff = null;
      await localForage.getItem(thisKey).then((value) => {
        if (value === null) {
          stuff = new Promise((resolve) => {
            fetch(`${apiPath}/${thisUrl}`)
              .then((response) => response.json())
              .then((data) => {
                // console.log(`New data and timestamp for ${thisKey}: ${masterTimestamp.dataSynced[thisKey]}`);
                const thePayload = {
                  items: data,
                  synced: masterTimestamp.dataSynced[thisKey],
                };
                commit(thisCommit, thePayload);
                resolve(data);
              });
          });
        } else if (value.items === null) {
          stuff = new Promise((resolve) => {
            fetch(`${apiPath}/${thisUrl}`)
              .then((response) => response.json())
              .then((data) => {
                // console.log(`New data and timestamp for ${thisKey}: ${masterTimestamp.dataSynced[thisKey]}`);
                localForage.getItem(thisKey).then((val) => {
                  const thePayload = {
                    items: data,
                    synced: masterTimestamp.dataSynced[thisKey],
                    settings: val.settings,
                  };
                  commit(thisCommit, thePayload);
                });
                resolve(data);
              });
          });
        } else {
          localTimestamp = value.synced;
          stuff = new Promise((resolve) => {
            if (masterTimestamp !== null) {
              // console.log(`Timestamp match for ${thisKey}: ${masterTimestamp.dataSynced[thisKey]} == ${value.synced}`);
            }
            if (masterTimestamp !== null && masterTimestamp.dataSynced[thisKey] === localTimestamp) {
              // console.log(thisKey);
              resolve(value.items);
              this.state[thisKey].items = value.items;
              this.state[thisKey].synced = value.synced;
              this.state[thisKey].settings = value.settings;
            } else {
              // console.log(`${thisKey} is being rewritten because timestamps do not match.`);
              fetch(`${apiPath}/${thisUrl}`)
                .then((response) => response.json())
                .then((data) => {
                  localForage.getItem(thisKey).then((val) => {
                    const thePayload = {
                      items: data,
                      synced: masterTimestamp.dataSynced[thisKey],
                      settings: val.settings,
                    };
                    commit(thisCommit, thePayload);
                  });
                  resolve(data);
                });
            }
          });
        }
      });
      const returnItems = Promise.resolve(stuff);
      return returnItems;
    },
  },
  strict: false,
});
